
import 'vue-fullpage.js/dist/scrolloverflow.min.js'  //Need to copy the file to this path from src folder
// import './scrolloverflow.min.js' 
import FullPage from 'vue-fullpage.js/dist/vue-fullpage.js'
import Vue from 'vue'
import Home from './Home.vue'
import {store} from "./store"
import VueResource from "vue-resource"
import VueFormulate from '@braid/vue-formulate'
// import VueLazyLoadVideo from './vue-lazyload-video'

Vue.config.productionTip = false


Vue.use(FullPage)
Vue.use(VueResource)
Vue.use(VueFormulate)
// Vue.use(VueLazyLoadVideo)

window.vueInst = new Vue({
  render: h => h(Home),
  store
}).$mount('#app')
