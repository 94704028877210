import Vue from 'vue'
import Vuex from 'vuex'
import '../../node_modules/@braid/vue-formulate/themes/snow/snow.scss'


Vue.use(Vuex)

export const store =  new Vuex.Store({
  state: {
    count: 0,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})