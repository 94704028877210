<template>
  <div>
    <full-page
      ref="fullpage"
      :options="options"
      id="fullpage"
      :skip-init="true"
    >
      <section class="fullpage-sec fp-noscroll flavours">
        <h2 class="sr-only">Flavours</h2>
        <div class="text" v-html="getFromModel(14124, 'Text')"></div>
        <!-- <div class="bottles">
          
        </div> -->
        <div class="bottle bottle--1">
          <img
            :data-src="getImageFromModel(getImageFileID(14125))"
            :alt="getFromModel(14125, 'Title')"
          />
        </div>
        <div class="bottle bottle--2">
          <img
            :data-src="getImageFromModel(getImageFileID(14127))"
            :alt="getFromModel(14127, 'Title')"
          />
        </div>
        <div class="bottle bottle--3">
          <img
            :data-src="getImageFromModel(getImageFileID(14126))"
            :alt="getFromModel(14126, 'Title')"
          />
        </div>
        <div class="bottle bottle--4">
          <img
            :data-src="getImageFromModel(getImageFileID(18473))"
            :alt="getFromModel(18473, 'Title')"
          />
        </div>
      </section>
      <!-- <section
        class="fullpage-sec fp-auto-height-responsive fp-noscroll who-is"
      >
        <h1 class="sec-title">
          <span>{{ whoIsTitle }}</span>
        </h1>
        <div class="icon">
          <img
            :src="`${rootUrl}${warehousePath}Home_header_button_image.png`"
            height="247"
            width="247"
            alt="Scroll to find out"
          />
          <img
            class="text"
            :src="`${rootUrl}${warehousePath}Home_header_button_text.png`"
            height="247"
            width="247"
            alt=""
          />
        </div>
      </section> -->
      
      <section class="fullpage-sec fp-noscroll quotes">
        <transition name="slideLeft" mode="in-out">
          <div
            v-show="showQuote"
            class="tr-slide-left quote-symbol quote-start"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="71.104"
              height="53.78"
              viewBox="0 0 71.104 53.78"
            >
              <path
                id="Tracciato_93"
                data-name="Tracciato 93"
                d="M8435.757,4234.218c-5.764,5.774-8.413,8.738-8.878,13.26-.154,2.027.16,3.431,2.031,3.429,6.081,0,11.23,6.073,11.237,14.649.007,9.823-6.068,16.532-15.736,16.539-8.732.007-16.689-6.692-16.7-18.854-.01-13.254,7.466-24.8,23.208-34.32C8434.349,4226.735,8438.717,4231.254,8435.757,4234.218Zm38.669-.028c-5.765,5.773-8.413,8.738-8.878,13.26-.154,2.027.158,3.43,2.03,3.428,6.08,0,11.231,6.073,11.238,14.649.007,9.823-6.07,16.532-15.736,16.539-8.732.007-16.689-6.692-16.7-18.854-.01-13.253,7.466-24.8,23.206-34.32C8473.173,4226.706,8477.386,4231.225,8474.426,4234.19Z"
                transform="translate(-8407.712 -4228.315)"
                fill="#1b1b3d"
              />
            </svg>
          </div>
        </transition>
        <transition name="slideLeft" mode="in-out">
          <div
            v-show="showQuote"
            class="tr-slide-left quote-text quote-text--1"
            v-html="getCurrentQuote().firstLine"
          ></div>
        </transition>
        <transition name="slideLeft" mode="in-out">
          <div v-show="showQuote" class="tr-slide-left quote-symbol quote-end">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70.946"
              height="53.867"
              viewBox="0 0 70.946 53.867"
            >
              <path
                id="Tracciato_94"
                data-name="Tracciato 94"
                d="M8427.413,4548.908c5.765-5.929,8.57-8.893,8.878-13.415.31-2.028,0-3.431-1.874-3.429-6.237,0-11.23-5.917-11.237-14.493-.007-9.823,6.069-16.688,15.735-16.695,8.576-.007,16.533,6.848,16.542,18.854.01,13.254-7.309,24.8-23.051,34.476C8428.821,4556.236,8424.453,4551.717,8427.413,4548.908Zm38.669-.028c5.764-5.929,8.568-8.894,9.034-13.416.153-2.028-.159-3.43-2.03-3.429-6.081,0-11.232-5.917-11.238-14.492-.008-9.824,6.07-16.689,15.736-16.7,8.576-.007,16.533,6.848,16.542,18.854.01,13.254-7.311,24.8-23.05,34.476C8467.49,4556.207,8463.277,4551.688,8466.082,4548.88Z"
                transform="translate(-8423.18 -4500.847)"
                fill="#1b1b3d"
              />
            </svg>
          </div>
        </transition>
        <transition name="slideLeft" mode="in-out">
          <div
            v-show="showQuote"
            class="tr-slide-left quote-autor"
            v-html="getCurrentQuote().author"
          ></div>
        </transition>
        <div class="flowing-text bad">
          <!-- <div class="text">{{getFromModel(12257, 'Title')}}</div> -->
          <div class="text" v-html="getFromModel(14123, 'Text')"></div>
        </div>
      </section>
      <section
        v-for="(item, index) in model[14128].Items"
        :key="index"
        :class="item.GridRowClass"
        :data-product-index="index"
      >
        <div class="video-wrapper">
          <!-- <video muted loop webkit-playsinline playsinline autoplay>
            <source :data-src="item.VideoIframe" type="video/mp4" />
          </video> -->
        </div>
        <div class="flavour-bottle">
          <img
            :data-src="getImageFromModel(item.ImageFileID)"
            :alt="item.Title"
          />
        </div>
        <div class="info">
          <div class="wrapper">
            <h3 v-html="item.TextInPop"></h3>
          </div>
          <div class="wrapper">
            <h2 class="sec-title">{{ item.Title }}</h2>
          </div>
          <transition name="extendedInfo">
            <!-- <div v-show="showExtendedInfo[index]" class="extended-info"> -->
            <div class="extended-info">
              <div class="wrapper">
                <div class="info-table">
                  <span class="info-table-item volume">
                    <span class="inner">{{ item.Title2.split(", ")[0] }}</span>
                  </span>
                  <div class="sep"></div>
                  <span class="info-table-item energy">
                    <span class="inner">{{ item.Title2.split(", ")[1] }}</span>
                  </span>
                  <div class="sep"></div>
                  <span class="info-table-item vegan">
                    <span class="inner">{{ item.Title2.split(", ")[2] }}</span>
                  </span>
                  <div class="sep"></div>
                  <span class="info-table-item alc">
                    <span class="inner">{{ item.Title2.split(", ")[3] }}</span>
                  </span>
                </div>
              </div>
              <div class="wrapper">
                <p class="text" v-html="item.Text"></p>
              </div>
              <div class="wrapper">
                <a :href="item.Url" class="btn btn-white">{{
                  item.ButtonText
                }}</a>
              </div>
            </div>
          </transition>
        </div>
      </section>
      <section
        v-for="(item, index) in model[14129].Items"
        :key="index + 100"
        :class="item.GridRowClass"
      >
        <div class="video-wrapper image">
          <img
            :data-src="getImageFromModel(item.ImageFileID)"
            :alt="item.Title"
          />
        </div>
        <div class="info">
          <div class="wrapper">
            <h3 v-html="item.TextInPop"></h3>
          </div>
          <div class="wrapper">
            <h2 class="sec-title">{{ item.Title }}</h2>
          </div>
          <div class="wrapper">
            <p class="text" v-html="item.Text"></p>
          </div>
          <div class="wrapper">
            <a :href="item.Url" class="btn btn-white">{{ item.ButtonText }}</a>
          </div>
        </div>
      </section>
      
      <section class="fullpage-sec fp-noscroll merch">
        <div class="flowing-text merch">
          <div class="text">
            <p>מרצ'נדייז של זלצרים</p>
            <p>מרצ'נדייז של זלצרים</p>
            <p>מרצ'נדייז של זלצרים</p>
            <p>מרצ'נדייז של זלצרים</p>
            <p>מרצ'נדייז של זלצרים</p>
            <p>מרצ'נדייז של זלצרים</p>
            <p>מרצ'נדייז של זלצרים</p>
            <p>מרצ'נדייז של זלצרים</p>
          </div>
        </div>
        <h2 class="sr-only">{{ getFromModel(14130, "Title") }}</h2>
        <div class="products">
          <div
            class="product"
            v-for="(item, index) in model[14130].Items"
            :key="index + 1000"
          >
            <a :href="item.Url" class="product-inner">
              <img
                :data-src="getImageFromModel(item.ImageFileID)"
                width="576"
                height="679"
                :alt="item.Title"
              />
            </a>
          </div>
        </div>
        <a :href="getFromModel(14130, 'Url')" class="btn btn-pink">לרכישה</a>
      </section>
      <section class="fullpage-sec about">
        <h2 class="sr-only">About Mr. Zeltser</h2>
        <div class="text" v-html="getFromModel(14117, 'Text')"></div>
        <div class="icons">
          <div class="icon icon1">
            <img
              :src="getImageFromModel(340392)"
              height="340"
              width="340"
              alt="Jumping icon"
            />
          </div>
          <div class="icon icon2">
            <img
              :src="getImageFromModel(340393)"
              height="340"
              width="340"
              alt="Jumping icon"
            />
          </div>
          <div class="icon icon3">
            <img
              :src="getImageFromModel(340394)"
              height="340"
              width="340"
              alt="Jumping icon"
            />
          </div>
        </div>
      </section>
      <section class="fullpage-sec fp-noscroll newsletter">
        <!-- <div class="newsletter-wrapper">
          <img class="newsletter-logo" width="262" height="182" :src="`${rootUrl}${warehousePath}logo.png`" alt="Mr. Zeltser logo">
          <h2 class="sec-title">Let’s get in touch</h2>
          <p class="text">SIGN UP TO OUR NESLETTER AND GET EXTRA DEALS</p>
          <FormulateForm
            v-model="formValues"
            action="#"
            class="newsletter-form"
            @submit="submitHandler"
          >
            <FormulateInput
              type="email"
              name="email"
              validation="required|email"
              placeholder="INSERT YOUR EMAIL"
            /> 
            <FormulateInput
              type="submit"
              label="SUBSCRIBE"
              class="btn btn--red"
            />
          </FormulateForm>
          <div class="hat hat1" v-bind:style="{ backgroundImage: `url(${rootUrl}${warehousePath}HOME_Contacts_image.png)` }"></div>
          <div class="hat hat2" v-bind:style="{ backgroundImage: `url(${rootUrl}${warehousePath}HOME_Contacts_image.png)` }"></div>
        </div> -->
      </section>
      <footer :class="isMobile ? 'fullpage-sec' : ''"></footer>
    </full-page>
    <button class="up" type="button" @click="goUp">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 330 330"
        style="enable-background: new 0 0 330 330"
        xml:space="preserve"
      >
        <path
          id="XMLID_224_"
          d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394  l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393  C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import logo from "@/assets/logo.png";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";

export default {
  data() {
    return {
      options: {
        licenseKey: "774B4B85-E5B34780-B15869C0-0BE74ED1",
        anchors: [
          // "who-is",
          "flavours",
          
          "quotes",
          // "flavours",
          "flavour-watermelon", 
          "flavour-lime",
          "flavour-orange",
          "flavour-mango",
          "starter-pack",
          "merch",
          "about",
          "newsletter",
          "footer",
        ],
        navigation: false,
        scrollingSpeed: window.innerWidth > 767 ? 1700 : 1200,
        fitToSectionDelay: 1000,
        easing: "easeInOutCubic",
        easingcss3: "ease",
        touchSensitivity: 4,
        keyboardScrolling: true,
        animateAnchor: true,
        dropEffect: false,
        sectionSelector: ".fullpage-sec",
        slideSelector: ".slide",
        scrollOverflow: true,
        scrollBar: false,
        lazyLoading: true,
        loopBottom: false,
        scrollOverflowReset: true,
        continuousVertical: false,
        css3: true,
        onLeave: function (origin, destination, direction) {
          window.vueInst.$children[0].setScrollingState(true);
          console.log(
            "scrolling state is ",
            window.vueInst.$children[0].isScrolling
          );

          if (origin.index == 0 && direction == "down") {
            document.querySelector("header").classList.add("header-hidden");
          }
          if (origin.index == 1 && direction == "up") {
            document.querySelector("header").classList.remove("header-hidden");
          }

          if (destination.anchor == "about" && direction == "down") {
            let $ = window.$;
            let delay = 0;
            setTimeout(function () {
              $(".about .text .wrapper .icon").each(function () {
                var icon = $(this);
                $(icon).css("transition-delay", delay + "s");
                if (!$(icon).parent().hasClass("visible")) {
                  $(icon).parent().addClass("visible");
                  setTimeout(function () {
                    $(icon).parent().removeClass("visible");
                  }, 2500);
                }

                delay += 0.4;
              });
              delay = 0;
            }, 2000);
          }

          if (origin.anchor == "about" && direction == "down") {
            let $ = window.$;
            $(".about").addClass("rendered");
          }

          if (destination.anchor == "quotes") {
            const items = document.querySelectorAll(".tr-slide-left");
            let delay = 0;
            for (let i = 0; i < items.length; i++) {
              items[i].style["transition-delay"] = delay + "s";
              delay = delay + 0.2;
            }

            window.vueInst.$children[0].showQuote = true;
          }

          // if (destination.anchor != 'quotes') {
          //   window.vueInst.$children[0].showQuote = false
          // }

          //
          // if (origin.anchor == "quotes") {
          // console.log(window.vueInst.$children[0].currentQuote);
          // if (
          //   window.vueInst.$children[0].currentQuote == 1 &&
          //   direction == "down"
          // ) {
          //   window.vueInst.$children[0].changeQuote("down");
          //   return false;
          // }
          // if (window.vueInst.$children[0].currentQuote == 2 && direction == 'up') {
          //     window.vueInst.$children[0].changeQuote('up');
          //     return false;
          //   }
          // }

          //
          // if (origin.anchor == "falvours" && direction == "up") {
          //   window.vueInst.$children[0].changeQuote("down");
          // }

          // play video when arriving to a section
          if (destination.item.querySelector(".video-wrapper video")) {
            destination.item.querySelector(".video-wrapper video").play();
          }

          if (destination.anchor.includes("flavour-") && direction == "down") {
            let $ = window.$;
            $(".fullpage-sec.active").addClass("rendered");
          }

          if (
            window.vueInst.$children[0].isMobile &&
            origin.anchor.includes("flavour-") &&
            direction == "down"
          ) {
            let $ = window.$;

            $(".fullpage-sec.active").addClass("extended");
            const currentProductIndex = $(".fullpage-sec.active").attr(
              "data-product-index"
            );

            if (
              window.vueInst.$children[0].showExtendedInfo[
                currentProductIndex
              ] != true
            ) {
              setTimeout(() => {
                window.vueInst.$children[0].showExtendedInfo[
                  currentProductIndex
                ] = true;
              }, "500");

              return false;
            }
          }

          //
          if (destination.anchor.includes("flavours") && direction == "down") {
            let $ = window.$;
            $("section.flavours").addClass("rendered");

            $(".bottles .bottle img").each(function () {
              $(this).attr("src", $(this).attr("data-src"));
            });

            $(".flavour-bottle img").each(function () {
              $(this).attr("src", $(this).attr("data-src"));
            });

            $(".video-wrapper video").each(function () {
              $(this)
                .find("source")
                .attr("src", $(this).find("source").attr("data-src"));
              $(this)[0].load();
              $(this)[0].play();
            });
          }

          if (
            destination.anchor.includes("starter-pack") &&
            direction == "down"
          ) {
            let $ = window.$;
            $(".starter-pack video").each(function () {
              $(this)
                .find("source")
                .attr("src", $(this).find("source").attr("data-src"));
              $(this)[0].load();
              $(this)[0].play();
            });
          }

          if (destination.anchor.includes("merch") && direction == "down") {
            let $ = window.$;
            $(".merch .product-inner img").each(function () {
              $(this).attr("src", $(this).attr("data-src"));
            });
          }
        },
        afterLoad: function (origin, destination, direction, trigger) {
          window.vueInst.$children[0].setScrollingState(false);
          window.vueInst.$children[0].scrollForcedOnce = false;
          console.log(
            "scrolling state is ",
            window.vueInst.$children[0].isScrolling
          );
        },

        afterRender: function () {
          //
          window.vueInst.$children[0].splitAboutText();
          window.vueInst.$children[0].splitFlavoursText();
          //
          document.querySelectorAll(".about .icon").forEach((icon) => {
            window.vueInst.$children[0].setIconRandomly(icon);
          });

          //
          const $ = window.$;

          const aboutText = $(".about .text")[0];

          aboutText.addEventListener("touchmove", function (e) {
            if ($(window).width() < 768) {
              let iconDelay = 0;
              $(".about .text .wrapper .icon").each(function () {
                $(this).css("transition-delay", iconDelay + "s");
                iconDelay += 0.5;

                $(this).css("opacity", "1");

                setTimeout(() => {
                  $(this).css("opacity", "0");
                }, "3000");
              });
            }
          });
        },
        afterSlideLoad: function (section, origin, destination, direction) {},
        onScrollOverflow: function (section, slide, position, direction) {
          console.log("inside scrolling section");
        },
      },
      model: window.data,
      whoIsTitle: "",
      whoIsTitleIndex: 0,
      formValues: {},
      logo: logo,
      rootUrl: window.location.origin + "/",
      warehousePath: "warehouse/temp/mrzeltser/",
      showQuote: false,
      currentQuote: 1,
      isMobile: false,
      showExtendedInfo: {},
      isScrolling: false,
      scrollForcedOnce: false,
    };
  },
  beforeCreate() {},
  created() {
    this.isMobile = this.checkIfMobile();

    this.model[14128].Items.forEach((item, index) => {
      this.showExtendedInfo[index] = true;
    });

    if (this.isMobile) {
      console.log();
      for (let i = 0; i < Object.keys(this.showExtendedInfo).length; i++) {
        this.showExtendedInfo[i] = false;
      }
    }
  },
  beforeMount() {},

  mounted() {
    const $ = window.$;

    this.setDynamicTitle();
    setInterval(this.setDynamicTitle, 1000);
    if ($(".introPop").hasClass("in")) {
      $(".btnsWrapper button.introPopUpBtn").click(function () {
        setTimeout(() => {
          document.querySelector("header").classList.remove("header-hidden");
        }, "500");
      });
    } else {
      setTimeout(() => {
        document.querySelector("header").classList.remove("header-hidden");
      }, "500");
    }

    // Open header when moving to top of the viewport
    $(document).mousemove(function (event) {
      if (!$(".flavours").hasClass("active")) {
        if (event.pageY <= 170 && !$(event.target).hasClass("flavours")) {
          $("header").removeClass("header-hidden");
        } else if (event.pageY > 170 && !$(event.target).hasClass("flavours")) {
          $("header").addClass("header-hidden");
        }
      }
    });

    //
    this.checkIfHasTouch();

    //
    $(document).ready(function () {
      console.log($(".newsletter .fp-tableCell"));
      console.log($(".FooterForm"));

      $(".newsletter .fp-tableCell").prepend($(".FooterForm"));
    });

    //
    // const vueInstance = this;
    // let ts;

    // $(document).bind('touchstart', function (e){
    //   console.log('touchstart')
    //   ts = e.originalEvent.touches[0].clientY;
    // });

    // $(document).bind('touchend', function (e){
    //   console.log(vueInstance.isScrolling);
    //   if (vueInstance.isScrolling) {
    //     console.log('touchend')
    //     var te = e.originalEvent.changedTouches[0].clientY;
    //     if (!vueInstance.scrollForcedOnce) {
    //       if(ts > te+5){
    //           vueInstance.scrollForcedOnce = true;
    //           window.fullpage_api.moveSectionDown();
    //       }else if(ts < te-5){
    //           vueInstance.scrollForcedOnce = true;
    //           window.fullpage_api.moveSectionUp();
    //       }
    //     }

    //     // vueInstance.setScrollingState(false)
    //   }
    // });

    // let delay = 0;
    // $('.about ').mousemove(function(event){
    //   setTimeout(function(){
    //     $('.about .text .wrapper .icon').each(function(){
    //       var icon = $(this);
    //       $(icon).css('transition-delay', delay + 's');
    //       if (!$(icon).parent().hasClass('visible')) {
    //         $(icon).parent().addClass('visible');
    //         setTimeout(function(){
    //           $(icon).parent().removeClass('visible');
    //         }, 300)
    //       }

    //       delay += 0.4;
    //     });
    //     delay = 0;
    //   }, 1000)

    // });
  },

  computed: {},
  watch: {},
  methods: {
    // incrementCount() {
    //   return this.$store.state.count++
    // },
    async submitHandler(data) {
      //this.$http.post('/someUrl', [body], [config]).then(successCallback, errorCallback);
    },

    /**
     * Loops through all the sections moodcms that are storing words for the animation (WHAT THE F*CK IS MR ZELTSRT)
     */
    setDynamicTitle() {
      const titleSectionsArr = [
        14113, 14131, 14134, 14132, 14133, 14114, 14115, 14116,
      ];

      this.whoIsTitle =
        this.model[titleSectionsArr[this.whoIsTitleIndex]].Title;
      if (this.whoIsTitleIndex == 7) {
        this.whoIsTitleIndex = 0;
      } else {
        this.whoIsTitleIndex++;
      }
    },

    /**
     * Get data from model (window.data) when it's ready
     * @param {*} sectionId - Id of the section
     * @param {*} data - Data that needs to pulled
     */
    getFromModel(sectionId, data) {
      return this.model ? this.model[sectionId][data] : "";
    },

    /**
     * Get data from subitem in model (window.data)
     * @param {*} sectionId - Id of the section
     * @param {*} itemNum - Number of item inside the section
     * @param {*} data - Data that needs to be pulled
     */
    getFromModelSubItem(sectionId, itemNum, data) {
      return this.model ? this.model[sectionId].Items[itemNum][data] : "";
    },

    /**
     * Get image from specific section
     * @param {*} id - section ID
     */
    getImageFromModel(id) {
      return this.model ? this.rootUrl + this.model["Images"][id] : "";
    },

    /**
     * Get image fileID by providing section ID
     * @param {*} sectionId
     */
    getImageFileID(sectionId) {
      return this.model ? this.model[sectionId].ImageFileID : "";
    },

    /**
     * Split text in aboyt section to add a nice animation
     */
    splitAboutText() {
      Splitting();

      // Split about text by lines
      const aboutTarget = document.querySelector(".about .text");
      const results = Splitting({ target: aboutTarget, by: "lines" });
      let delay = 0.6;
      results[0].lines.forEach((line, index) => {
        line.forEach((word) => {
          word.classList.add("data-line-" + index);
          word.style["transition-delay"] = delay + "s";
        });
        delay = delay + 0.1;
      });
    },

    /**
     * Split text in Flavours section to add the icons to a few random words
     */
    splitFlavoursText() {
      Splitting();

      // Split flavours text by chars
      const flavoursTarget = document.querySelector(".flavours .text");
      const results = Splitting({ target: flavoursTarget, by: "chars" });
      let delay = 0;
      console.log(results[0]);
      results[0].chars.forEach((char, index) => {
        char.classList.add("data-line-" + index);
        char.style["transition-delay"] = delay + "s";

        delay = delay + 0.05;
      });
    },

    getRandomNumber: function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    /**
     * Put icon randomly to ne of the words in About section
     * @param {*} iconElement
     */
    setIconRandomly(iconElement) {
      const aboutWordsCollection = document.querySelectorAll(
        ".about div.splitting .word"
      );
      const aboutWordsCount = aboutWordsCollection.length;
      const randomWord = this.getRandomNumber(0, aboutWordsCount);
      const wrapper = document.createElement("div");
      wrapper.classList.add("wrapper");
      aboutWordsCollection[randomWord].parentNode.insertBefore(
        wrapper,
        aboutWordsCollection[randomWord]
      );
      wrapper.appendChild(aboutWordsCollection[randomWord]);
      wrapper.appendChild(iconElement);
    },

    /**
     * Set the qurrent quote to display
     *
     * @returns {Object} quote to display
     */
    getCurrentQuote() {
      const quote = {};
      if (window.vueInst) {
        quote.firstLine = this.getFromModel(14121, "Text").split("\n")[0];
        quote.author = this.getFromModel(14121, "Text").split("\n")[1];

        // if (window.vueInst.$children[0].currentQuote == 1) {
        //   quote.firstLine = this.getFromModel(14121, "Text").split("\n")[0];
        //   // quote.secondLine = this.getFromModel(14121, 'Text').split('\n')[1];
        //   quote.author = this.getFromModel(14121, "Text").split("\n")[1];
        // }
        // else if (window.vueInst.$children[0].currentQuote == 2) {
        //   quote.firstLine = this.getFromModel(14122, "Text").split("\n")[0];
        //   // quote.secondLine = this.getFromModel(14122, 'Text').split('\n')[1];
        //   quote.author = this.getFromModel(14122, "Text").split("\n")[1];
        // }
      }

      return quote;
    },

    /**
     * Change quote based on the scroll direction
     * @param {String} direction scroll direction
     */
    // changeQuote(direction) {
    //   window.vueInst.$children[0].showQuote = false;
    //   setTimeout(() => {
    //     if (direction == "down") {
    //       window.vueInst.$children[0].currentQuote = 2;
    //       window.$("section.quotes").addClass("quote2");
    //     } else if (direction == "up") {
    //       window.vueInst.$children[0].currentQuote = 1;
    //       window.$("section.quotes").removeClass("quote2");
    //     }

    //     window.vueInst.$children[0].showQuote = true;
    //   }, "1500");
    // },

    checkIfMobile() {
      console.log(window.innerWidth);
      if (window.innerWidth < 768) {
        return true;
      }
      return false;
    },

    goUp() {
      const $ = window.$;
      this.$refs.fullpage.api.moveTo("flavours");
      $("header").removeClass("header-hidden");
    },

    checkIfHasTouch() {
      const $ = window.$;
      if (
        !("ontouchstart" in window) ||
        !(navigator.maxTouchPoints > 0) ||
        !(navigator.msMaxTouchPoints > 0)
      ) {
        $("body").addClass("no-touch");
      }
    },

    setScrollingState(inScroll) {
      this.isScrolling = inScroll;
    },

    sendNewsletterForm() {
      // submitFormDynamicForm('#dynamic-form-947_22adcee8-b401-4b09-b853-4f01240a3b65', '/DynamicFormSubmitNew?FormID=947', function (data) {
      //       if (data == "OK") {
      //           window.location = "/FormResultPage?FormID=947";
      //       }
      //   });
    },

    // openExtendedInfo() {
    //   let $ = window.$;
    //   console.log(this.$refs.fullpage.api)

    //   $('.fullpage-sec.active').addClass('extended');
    //   const currentProductIndex = $('.fullpage-sec.active').attr('data-product-index');
    //   this.showExtendedInfo[currentProductIndex] = true;
    // }
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
}

body.scroll-blocked {
  pointer-events: none;
}

header {
  min-height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #1b1b3d;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  display: none;
}

header.header-flex {
  display: flex;
}

header.header-transition {
  transition: 0.6s;
}

header.header-hidden {
  transform: translateY(-170px);
}

header.header-hidden .top-strip {
  transform: translateY(-120px);
}

#fullpage {
  padding-top: 170px;
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

img {
  max-width: 100%;
  height: auto;
}

section {
  text-align: center;
}

.who-is span {
  color: #1b1b3d;
  letter-spacing: 12px;
  font-family: var(--thirdFont);
  font-size: calc(var(--vw) * 0.27);
  text-transform: uppercase;
}

.who-is {
  background: #f2f1ea;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.sec-title {
  margin: 0;
}

.who-is .icon {
  position: absolute;
  right: 0;
  width: 247px;
  height: 247px;
  bottom: 6%;
}

.who-is .icon img {
  position: absolute;
  top: inherit;
  right: 200px;
}

.who-is .icon img.text {
  -webkit-animation-name: spin;
  animation-name: spin;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.about {
  background: #f2f1ea;
  border-bottom: 2px solid #1b1b3d;
  overflow: hidden;
}

.about .text {
  width: 86.4%;
  margin: 0 auto;
  color: #1b1b3d;
  font-size: 300%;
  text-align: right;
  padding: 70px 0;
}

@media (min-width: 1920px) {
  .about .text {
    font-size: 2.4vw;
  }
}

.quotes {
  background: #876fa5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 1s;
}

.quote-symbol {
  width: 71px;
  height: 54px;
}

.quote-start {
  margin-bottom: 40px;
}

.quote-end {
  margin-top: 35px;
}

.quote-text {
  color: #f7f6ef;
  line-height: 1.1;
  font-size: 385%;
  font-family: var(--thirdFont);
  text-transform: uppercase;
}

.quotes .quote-autor {
  margin-top: 22%;
  color: #1b1b3d;
  font-size: 133.4%;
}

.flowing-text {
  border-top: 2px solid #1b1b3d;
  border-bottom: 2px solid #1b1b3d;
  padding-top: 2px;
  padding-bottom: 114px;
  color: #f7f6ef;
  font-size: 406%;
  font-family: var(--thirdFont);
  text-transform: uppercase;
  direction: ltr;
}

.flowing-text.bad {
  background: #21ab73;
  padding: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 9px;
  padding-top: 3px;
}

.flowing-text.merch {
  background: #876fa5;
}

.flavours {
  background: #ffc6bd;
  border-bottom: 2px solid #1b1b3d;
  overflow: hidden;
  width: 100%;
}

.flavours .text {
  color: #1b1b3d;
  font-size: 500%;
  line-height: 1;
  position: relative;
  z-index: 2;
  font-family: var(--secoundFont);
}

.flavours .bottle img {
  max-height: calc(var(--vh) * 0.4);
}

.flavours .bottle {
  position: absolute;
  transform: translate(-50%, -50%) translateY(150px);
  top: 50%;
  left: 50%;
  transition: 3s;
}

.flavours .bottle--1 {
    transform: translate(-50%, -50%) rotate(-10deg) translateY(150px);
    left: 14.5%;
    top: 54%;
    z-index: 1;
}

.flavours .bottle--2 {
    transform: translate(-50%, -50%) rotate(11deg) translateY(0);
    top: 10%;
    left: 40%;
}

.flavours .bottle--3 {
    transform: translate(-50%, -50%) rotate(8deg) translateY(150px);
    left: auto;
    right: -1.4%;
    z-index: 3;
    top: 45%;
}

.flavours .bottle--4 {
    transform: translate(-50%, -50%) rotate(14deg) translateY(150px);
    top: auto;
    left: 50%;
    bottom: -10%;
}
section.active .bottle--1 {
    transform: translate(-50%, -50%) rotate(-10deg) translateY(0);
}

section.active .bottle--2 {
    transform: translate(-50%, -50%) rotate(11deg) translateY(150px);
}

section.active .bottle--3 {
    transform: translate(-50%, -50%) rotate(8deg) translateY(0);
}
section.active .bottle--4 {
    transform: translate(-50%, -50%) rotate(14deg) translateY(0);
}

.flavour,
.starter-pack {
  display: flex;
  overflow: hidden;
}

.flavour {
  border-bottom: 2px solid #1b1b3d;
}

.flavour-bottle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(30deg);
  transition: 2s;
  z-index: 1;
}

.flavour.active .flavour-bottle,
.flavour.rendered .flavour-bottle {
  transform: translate(-50%, -50%) rotate(4deg);
}

.flavour.fp-completely:not(.active) .flavour-bottle {
  transform: translate(-50%, -50%) rotate(30deg);
}

.flavour .video-wrapper,
.starter-pack .video-wrapper {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.flavour .info,
.starter-pack .info {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flavour-bottle img {
  max-height: 700px;
}

.flavour .video-wrapper,
.starter-pack .video-wrapper {
  transition: 2s;
  transform: translateY(100%);
}

.flavour.active .video-wrapper,
.flavour.fp-completely:not(.active) .video-wrapper,
.starter-pack.active .video-wrapper,
.starter-pack.fp-completely:not(.active) .video-wrapper,
.starter-pack.rendered .video-wrapper {
  transform: translateY(0);
}

.flavour-lime .video-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.flavour-lime .info {
  background: #21ab73;
  padding: 80px 0;
}

.starter-pack .info {
  padding: 80px 0;
}

.flavour .info .text,
.starter-pack .info .text {
  max-width: 418px;
  color: #1b1b3d;
  text-transform: uppercase;
}

.flavour .info h3,
.starter-pack .info h3 {
  color: #1b1b3d;
  font-size: 142.5%;
  margin: 0 0 30px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.flavour .info .sec-title,
.starter-pack .info .sec-title {
  color: #f7f6ef;
  font-size: 500%;
  margin-bottom: 33px;
  font-family: var(--secoundFont);
}

.btn {
  color: #1b1b3d;
  font-size: 222.3%;
  border: 2px solid #1b1b3d;
  border-radius: 24px;
  background: #f7f6ef;
  line-height: 1;
  min-width: 195px;
  font-family: var(--thirdFont);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 4px;
  text-transform: uppercase;
}

.flavour .btn,
.starter-pack .btn {
  margin-top: 70px;
}

.info-table-item {
  color: #282a4e;
  position: relative;
  padding: 5px 25px;
}

.info-table {
  border: 2px solid #282a4e;
  display: flex;
  margin-bottom: 57px;
  font-family: var(--thirdFont);
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.info-table-item:last-of-type {
  border: 0;
}

section.merch {
  background: #f7f6ef;
  border-bottom: 2px solid #1b1b3d;
}

.merch .products {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 45px;
  direction: ltr;
}

.merch .products .product {
  padding: 0 14px;
}

.merch .products .product:first-of-type {
  margin-left: 4%;
}

.btn.btn-pink {
  background: #ffc6bd;
  align-self: center;
}

.merch .product-inner {
  border: 2px solid #1b1b3d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsletter .sec-title {
  color: #eb4827;
  font-size: 500%;
  margin-bottom: 40px;
  font-family: var(--secoundFont);
}

.newsletter .text {
  max-width: 260px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.newsletter img {
  margin-bottom: 20px;
}

section.newsletter {
  background: #ffc6bd;
  position: relative;
}

.newsletter .formulate-input .formulate-input-element {
  margin: 0 auto;
  width: 80%;
  max-width: 686px;
}

.newsletter .formulate-input .formulate-input-element input {
  text-align: center;
  color: #1b1b3d;
  border: none;
  border-bottom: 2px solid #1b1b3d;
  border-radius: 0;
  padding: 11px;
}

.newsletter .formulate-input .formulate-input-element input::-moz-placeholder {
  color: #1b1b3d;
}

.newsletter
  .formulate-input
  .formulate-input-element
  input:-ms-input-placeholder {
  color: #1b1b3d;
}

.newsletter .formulate-input .formulate-input-element input::placeholder {
  color: #1b1b3d;
}

.newsletter .formulate-input[data-classification="button"] button {
  border-radius: 24px;
  background: #eb4827;
  border: 2px solid #282a4e;
  color: #282a4e;
  font-family: var(--thirdFont);
  width: auto;
  min-width: 217px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 4px;
  line-height: 1;
  text-transform: uppercase;
}

.newsletter .formulate-input .formulate-input-element--submit {
  max-width: 100%;
  width: 100%;
}

.newsletter .btn.btn--red.formulate-input {
  padding: 0;
  border: none;
  font-size: 247%;
  margin-top: 10px;
  max-width: 214px;
  margin-left: auto;
  margin-right: auto;
}

.newsletter .hat {
  display: block;
  height: 583px;
  width: 20vw;
  background-size: 142%;
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
}

.newsletter .hat.hat1 {
  background-position: 100%;
  left: 0;
}

.newsletter .hat.hat2 {
  right: 0;
  background-position: 0;
}

.newsletter .formulate-input[data-classification="text"] input:focus {
  border: none;
  border-bottom: 2px solid #1b1b3d;
}

.quotes .fp-tableCell,
.who-is .fp-tableCell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quotes .fp-tableCell {
  flex-direction: column;
  padding-bottom: 109px;
}

.flavours .fp-tableCell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flavour .fp-tableCell,
.starter-pack .fp-tableCell {
  display: flex;
  width: 100%;
  background: #ece6de;
}

.starter-pack .fp-tableCell {
  flex-direction: row-reverse;
}

.flowing-text.merch {
  padding: 6px 0;
  margin-bottom: 60px;
}

.merch .fp-tableCell {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 100px;
}

.newsletter-wrapper {
  padding: 20px 0 39px;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.newsletter .fp-tableCell {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.newsletter-form {
  width: 100%;
}

.flavour-mango .info {
  padding: 80px 0;
  background: #f4a411;
}

.flavour-mango .fp-tableCell {
  flex-direction: row-reverse;
}

.flavour-orange .info {
  padding: 80px 0;
  background: #eb4827;
}

.starter-pack .info {
  background: #ffc6bd;
}

.starter-pack h3 {
  color: #1b1b3d;
  font-family: var(--thirdFont);
  font-size: 223%;
  text-transform: uppercase;
}

.starter-pack .info .sec-title {
  color: #1b1b3d;
}

.about .fp-scroller {
  transition-duration: 1s !important;
}

.about .text .icon {
  position: absolute;
  z-index: 1;
}

.about .text .wrapper {
  display: inline-block;
  position: relative;
  transform: none;
  z-index: 1;
}

.about .text .wrapper.visible .icon,
.about .text .wrapper:hover .icon {
  transform: scale(1);
  opacity: 1;
}

.about .icon {
  position: absolute;
}

.about .text .wrapper .icon {
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s;
  opacity: 0;
  z-index: 10;
  transform: scale(0);
}

.about .text .wrapper .icon img {
  width: calc(var(--vw) * 0.09);
  height: auto;
  max-width: unset;
  transform: none;
}

/* .quotes.quote2 {
  background: #f3a410;
} */

.bottles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 3s;
  transform: translateY(150px);
}

section.active .bottles {
  transform: translateY(0);
}

.flavour .info .wrapper,
.starter-pack .info .wrapper {
  position: relative;
  overflow: hidden;
}

.flavour .info .wrapper :not(.sep),
.starter-pack .info .wrapper * {
  transition: 1s;
  transition-delay: 0.5s;
  transform: translateY(250%);
}

.flavour.active.extended .info .wrapper :not(.sep),
.flavour.fp-completely:not(.active) .info .wrapper *,
.starter-pack.active .info .wrapper *,
.starter-pack.fp-completely:not(.active) .info .wrapper * {
  transform: translateY(0);
}

@media (min-width: 768px) {
  .flavour.active .info .wrapper :not(.sep) {
    transform: translateY(0);
  }
}
.video-wrapper video {
  max-width: 100%;
}

.flavour .info h3,
.starter-pack .info h3 {
  font-family: var(--thirdFont);
  text-transform: uppercase;
}

.video-wrapper video::-webkit-media-controls-panel {
  opacity: 0 !important;
  pointer-events: none;
}

button.up {
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 99;
  background: #ffc0b7;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  border: 1px solid #000;
}

button.up svg {
  width: 24px;
  margin-top: -3px;
}

.flavour:nth-of-type(odd) .info {
  position: relative;
}

.flavour:nth-of-type(odd) .info:before {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  position: absolute;
  left: -1px;
  top: 0;
  background: #1b1b3d;
}

.flavour:nth-of-type(2n) .info {
  position: relative;
}

.flavour:nth-of-type(2n) .info:before {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  position: absolute;
  right: -1px;
  top: 0;
  background: #1b1b3d;
}

.starter-pack .info {
  position: relative;
}

.starter-pack .info:before {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  position: absolute;
  right: -1px;
  top: 0;
  background: #1b1b3d;
}

.flowing-text p {
  display: inline-block;
  direction: rtl;
  margin: 0 15px;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(1turn);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(1turn);
  }

  to {
    transform: rotate(0deg);
  }
}

.slideLeft-enter-active {
  transition: all 1s ease;
}

.slideLeft-leave-active {
  transition: all 1s ease;
  opacity: 0;
}

.slideLeft-enter,
.slideLeft-leave-to {
  transform: translateX(600px);
  opacity: 0;
}

.extendedInfo-enter-active,
.extendedInfo-leave-active {
  transition: all 1.5s ease;
  max-height: 300px;
}

.extendedInfo-enter,
.extendedInfo-leave-to {
  opacity: 0;
  max-height: 0;
}

.about .text * {
  transform: translateY(100px);
  transition: 1.5s;
}

.about.active .text *,
.about.fp-Completely:not(.active) .text *,
.about.rendered .text * {
  transform: translateY(0);
}

.flavours .splitting .char {
  transition: 0.5s;
  transform: translateY(80px);
  opacity: 0;
  will-change: transform;
}

.flavours.active .splitting .char,
.flavours.rendered .splitting .char {
  transform: translateY(0);
  opacity: 1;
}

#side-contact-form.mobile-form {
  display: none;
}

.extended-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flavours:not(.active) .splitting .char {
  transition-delay: 0s !important;
}

.sep {
  width: 2px;
  background: #282a4e;
}

.active.fp-completely .btn {
  transition: 0.4s !important;
  transition-delay: unset !important;
}

.btn.btn-white:focus,
.btn.btn-white:hover {
  background: #1b1b3d;
  color: #fff;
}

.btn.btn-pink:focus,
.btn.btn-pink:hover {
  background: #1b1b3d;
  color: #ffc6bd;
}

/* .quote2 svg path {
  fill: #eb4827;
}

.quotes.quote2 .quote-autor {
  color: #eb4827;
} */

.quote-symbol svg {
  width: 100%;
  height: auto;
}

.top-strip a.shop-page:focus,
.top-strip a.shop-page:hover {
  color: #eb4827;
}

.top-strip a.shop-page {
  transition: 0.3s;
}

@media (max-width: 1440px) {
  .flavours .text {
    font-size: calc(var(--vw) * 0.06);
  }

  .who-is .icon img {
    width: calc(var(--vw) * 0.16);
    right: calc(var(--vw) * 0.1);
  }

  .who-is .icon {
    width: calc(var(--vw) * 0.16);
    height: calc(var(--vw) * 0.16);
    top: 50%;
    bottom: auto;
    transform: translateY(5%);
  }

  .about .text {
    font-size: 5vw;
    padding-bottom: 70vw;
  }

  .flavour-bottle img {
    max-height: 30vw;
  }

  .flavour .info .sec-title,
  .starter-pack .info .sec-title {
    font-size: 5.8vw;
  }

  .flavour-orange .info {
    padding: 40px;
  }

  .flavour .info .text,
  .starter-pack .info .text {
    font-size: 90%;
  }

  .newsletter .sec-title {
    font-size: 6vw;
  }

  .newsletter img {
    width: 18vw;
  }

  .flowing-text {
    font-size: 270%;
  }

  .flowing-text.bad {
    padding: 0 0 6px;
  }
}

@media (max-width: 991px) {
  .who-is span {
    font-size: calc(var(--vw) * 0.25);
    letter-spacing: 10px;
  }

  .quote-text {
    font-size: 6vw;
  }

  .quotes .quote-autor {
    font-size: 3vw;
  }

  .flavours .text {
    font-size: 9vw;
  }

  .info-table-item {
    padding: 4px 13px;
  }

  .info-table {
    margin-bottom: 45px;
  }

  .flavour .info,
  .starter-pack .info {
    padding: 40px;
  }

  .flavour .info .text,
  .starter-pack .info .text {
    font-size: 80%;
    max-width: 260px;
  }

  .flavour .btn,
  .starter-pack .btn {
    margin-top: 50px;
  }

  .btn {
    font-size: 190%;
    min-width: 160px;
  }

  .flavour .info .sec-title,
  .starter-pack .info .sec-title {
    font-size: 5.7vw;
  }

  .flavour .info h3,
  .starter-pack .info h3 {
    font-size: 3.5vw;
    margin-bottom: 20px;
  }

  .flowing-text {
    font-size: 300%;
  }

  .newsletter .formulate-input[data-classification="button"] button {
    font-size: 77%;
    width: 180px;
    min-width: unset;
  }

  .newsletter .btn.btn--red.formulate-input {
    max-width: 120px;
  }
}

@media (min-width: 768px) and (max-height: 700px) {
  .flowing-text {
    font-size: 156%;
  }

  .footer-text-divided {
    padding: 50px 15px;
  }

  .newsletter .sec-title {
    font-size: calc(var(--vw) * 0.04);
    margin-bottom: 20px;
  }

  .newsletter .hat {
    height: calc(var(--vh) * 0.5);
    width: calc(var(--vh) * 0.4);
  }

  .newsletter img {
    width: calc(var(--vh) * 0.2);
  }

  .newsletter .text {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .flavour:nth-of-type(2n) .info:before,
  .flavour:nth-of-type(odd) .info:before,
  .starter-pack .info:before {
    display: none;
  }

  .who-is span {
    letter-spacing: 4px;
  }

  .who-is .icon,
  .who-is .icon img {
    width: calc(var(--vw) * 0.32);
  }

  .about .text {
    font-size: 5.5vw;
    padding: 15px 0;
  }

  .quote-text {
    font-size: 8vw;
    line-height: 1.3;
  }

  .quotes .quote-autor {
    font-size: 4vw;
  }

  .quote-symbol {
    width: 49px;
    background-size: contain;
    height: 37px;
  }

  .flavours .bottle img {
    max-height: calc(var(--vw) * 0.7);
  }

  .flavours .text {
    line-height: 1.4;
  }

  .flavour .fp-tableCell,
  .starter-pack .fp-tableCell {
    flex-direction: column;
  }

  .flavour .video-wrapper,
  .starter-pack .video-wrapper {
    width: 100%;
    height: 50%;
  }

  .flavour .info,
  .starter-pack .info {
    width: 100%;
    padding: 27px 20px 20px;
    height: 50%;
    transition: 2s;
  }

  .flavour .info .text,
  .starter-pack .info .text {
    max-width: unset;
  }

  .video-wrapper video {
    max-height: 100%;
  }

  .flavour .info h3,
  .starter-pack .info h3 {
    font-size: 100%;
  }

  .flavour .info .sec-title,
  .starter-pack .info .sec-title {
    font-size: 220%;
    margin-bottom: 30px;
  }

  .info-table {
    margin-bottom: 30px;
  }

  .flavour .btn,
  .starter-pack .btn {
    margin-top: 30px;
  }

  .info-table-item,
  .newsletter .text {
    font-size: 90%;
  }

  .newsletter .sec-title {
    font-size: 9vw;
  }

  .about .text .wrapper .icon img {
    width: calc(var(--vw) * 0.25);
  }

  .flowing-text {
    padding-bottom: 87px;
  }

  .flowing-text.merch {
    padding-top: 0;
  }

  .newsletter .hat.hat1 {
    left: -20px;
  }

  .newsletter .hat.hat2 {
    right: -20px;
  }

  #SiteFooter {
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  .footer-text-divided {
    justify-content: center;
    flex-grow: 1;
  }

  .flavour .video-wrapper,
  .starter-pack .video-wrapper {
    transform: translateY(0);
  }

  .flavour.extended .video-wrapper {
    height: 0;
  }

  .flavour.extended .info {
    height: 100%;
  }

  .flavour .info .wrapper {
    transform: translateY(41vw);
    transition: 2s;
  }

  .flavour-bottle img {
    max-height: 60vw;
  }

  .flavour.active .flavour-bottle,
  .flavour.rendered .flavour-bottle {
    transform: translate(-50%, -10%) rotate(8deg);
  }

  .flavour.extended .info .wrapper {
    transform: translateY(29vw);
  }

  .flavour.active.extended .flavour-bottle,
  .flavour.extended .flavour-bottle,
  .flavour.fp-completely:not(.active) .flavour-bottle {
    transform: translate(-50%, -137%) rotate(0deg);
  }

  .flavour:nth-of-type(odd) .info {
    border-left: none;
  }

  .flavour:nth-of-type(2n) .info,
  .starter-pack .info {
    border-right: none;
  }

  .flavours .bottle--1 {
    top: 72%;
  }

  .flavours .bottle--3 {
        top: 87%;
        right: -30%;
  }
  .flavours .bottle--4 {
        top: 40%;
        bottom: auto;
        left: auto;
        right: -40%;
  }
}

@media (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.25) {
  .footer-text-divided,
  .newsletter-wrapper {
    zoom: 0.8;
  }

  .newsletter .formulate-input[data-classification="button"] button {
    zoom: 1.25;
  }

  .newsletter img {
    width: 11vw;
  }

  .newsletter .sec-title {
    font-size: 4vw;
  }
}
</style>
